import React, { useEffect, useState, useRef } from 'react';
import './styleMobileBalances.scss';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Icon } from '@material-ui/core';
import { getInventoryAsync } from '../../../actions/userActions';
import { useSelector } from 'react-redux';
import { encryptData } from '../../../utils/encrypt.service';
import SearchBar from "material-ui-search-bar";

export const MobileBalancesPage = () => {

    const requestH = useSelector((state) => state.user.request);

    const [information, setInfromation] = useState()
    const [informationClone, setInfromationClone] = useState()
    const [searchedValuesGeneral, setSearchedValuesGeneral] = useState("");

    useEffect(() => {
        getInformation()
    }, [])

    const getInformation = async () => {

        const newData = encryptData(JSON.stringify({ "code": 103 }))

        await getInventoryAsync({ "palabra": newData }, requestH).then(resp => {
            if (resp.valido && resp.valido === 1) {
                setInfromation(resp.addenda)
                setInfromationClone(resp.addenda)
            }
        });
    }

    function buscarElementos(searchValue) {
        const codigoLower = searchValue.toLowerCase();
        const descripcionLower = searchValue.toLowerCase();
        const loteLower = searchValue.toLowerCase();

        const informationCloneClone = structuredClone(informationClone)

        const resultado = informationCloneClone.filter(elemento =>
            elemento.inv_prd_code.toLowerCase().includes(codigoLower) ||
            elemento.inv_prd_desc.toLowerCase().includes(descripcionLower) ||
            elemento.inv_prd_lote.toLowerCase().includes(loteLower)
        );

        return resultado.length > 0 ? resultado : [];
    }

    const requestSearch = (searchedVal) => {
        if (searchedVal.length === 0) {
            cancelSearch()
        } else {
            const newArray = buscarElementos(searchedVal)
            setInfromation(newArray)
        }
    }

    const cancelSearch = () => {
        console.log('Entro aqui')
        setSearchedValuesGeneral("")
        setInfromation(informationClone)
    };

    /* TABLE COLUMNS */
    const tableColumns = [
        { id: 'id', label: 'NO. PRODUCTO', minWidth: '5%', align: 'center' },
        { id: 'balanceDesc', label: 'DESCRIPCIÓN', minWidth: '45%', align: 'center' },
        { id: 'color', label: 'COLOR', minWidth: '5%', align: 'center' },
        { id: 'talla', label: 'TALLA', minWidth: '5%', align: 'center' },
        { id: 'balanceLote', label: 'LOTE', minWidth: '10%', align: 'center' },
        { id: 'balancePedi', label: 'PEDIMENTO', minWidth: '10%', align: 'center' },
        { id: 'balance', label: 'PRECIO UNITARIO', minWidth: '10%', align: 'center' },
        { id: 'inv_stock_congreso', label: 'STOCK CONGRESO', minWidth: '5%', align: 'center' },
        { id: 'inv_stock_almacenag', label: 'STOCK GUADALAJARA', minWidth: '5%', align: 'center' }

    ]

    /* TABLE DATA (DEMO) */
    function createTableData(id, balanceDesc, balanceLote, balancePedi, balance) {
        return { id, balanceDesc, balanceLote, balancePedi, balance };
    }

    function formatearNumeroComoMoneda(numero) {
        if (isNaN(numero)) { return "No es un número válido"; }
        const numeroFormateado = parseFloat(numero).toFixed(2);
        const partes = numeroFormateado.split(".");
        partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        const resultado = `$ ${partes.join(".")} MXN`;
        return resultado;
    }

    function getcolorProduct(information) {
        return (information.inv_prd_color && information.inv_prd_color.length > 0) ? information.inv_prd_color : information.ColorName
    }

    function getTallaProduct(information) {
        return (information.sizeName && information.sizeName.length > 0) ? information.sizeName : ''
    }

    return (
        <div className='container' style={{ marginBottom: '10vh' }}>
            <SubNavBarComponent title={'VENTA MÓVIL'} />
            <div className='header'>
                <div className='title'>
                    <p>SALDOS DISPONIBLES PARA VENTA MÓVIL</p>
                    <hr />
                </div>
                <SearchBar
                    value={searchedValuesGeneral}
                    onChange={(searchVal) => { requestSearch(searchVal) }}
                    onCancelSearch={() => cancelSearch()}
                    placeholder={'Buscar'}
                    style={{
                        width: '25%',
                        height: '46px',
                        boxSizing: 'border-box',
                        background: '#FFFFFF',
                        border: '1px solid rgba(0, 0, 0, 0.5)',
                        borderRadius: '4px',
                    }}
                />
            </div>

            {/* -------------------------- TABLE ----------------------------- */}
            <div className='tableContainer' >
                <TableContainer style={{ maxHeight: 600 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {tableColumns.map((column) => {
                                    return (
                                        <TableCell key={column.id} align={column.align} width={column.minWidth}>
                                            <div style={{ display: 'flex', justifyContent: column.align, whiteSpace: 'pre-line' }}>
                                                {column.label}
                                            </div>
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {information && information.map((val) => {
                                return (
                                    <TableRow key={val.folio}>
                                        {tableColumns.map((col) => {
                                            const value = val[col.id];

                                            const productCode = val.inv_prd_code
                                            const productDescription = val.inv_prd_desc
                                            const lote = val.inv_prd_lote
                                            const pedimento = val.inv_prd_pedimento
                                            const price = val.inv_prd_precio

                                            return (
                                                <TableCell key={col.id} align={col.align}>{
                                                    col.id == 'id' ? productCode :
                                                        col.id == 'color' ? getcolorProduct(val) :
                                                            col.id == 'talla' ? getTallaProduct(val) :
                                                                col.id == 'balanceDesc' ? productDescription :
                                                                    col.id == 'balanceLote' ? lote :
                                                                        col.id == 'balancePedi' ? pedimento :
                                                                            col.id == 'balance' ? formatearNumeroComoMoneda(price) :
                                                                                value
                                                }
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}